<template>
    <section class="tt-center">
        <Nav
            class="mt-5 mb-4"
            route-name="TechnologyTransfer"
            parent-page-name="Трансфер технологий"
            current-page-name="Правовые дайджесты"
        />

        <div class="tt-center__main-img gpn-center d-none d-xl-flex">
            <div class="tt-center__blurred-img gpn-center mt-lg-auto ms-auto d-flex">
                <h2 class="tt-center__title mt-lg-auto">
                    Правовые дайджесты
                </h2>
                <img
                    src="../../assets/page-logo.svg"
                    class="d-block ms-auto mt-auto mb-3 d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="col-12 mt-5">
            <div class="row d-flex mx-1">
                <div class="col-12 col-md-6">
                    <div class="d-flex">
                        <img class="w-100" src="../../assets/law-digest-about.png" alt="">
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-3">
                    <h3 class="tt-center-goal__title text-center text-lg-start mx-3">
                        О проекте
                    </h3>

                    <p class="tt-center-goal__text mx-3 mt-4">
                        Подборка ежеквартальных обзоров (дайджестов) изменений нормативно-правового регулирования и правоприменительной практики в сфере правовой поддержки трансфера технологий, а также лучшие правовые практики из кейсов, возникающих в работе центра трансфера технологий и его партнеров.
                    </p>
                </div>
            </div>
        </div>

        <div class="col-12 mt-5">
            <h3 class="tt-center-goal__title text-center text-lg-start mx-3">
                Правовые дайджесты ЦТТ 2023
            </h3>

            <ul class="list-unstyled law-card-list mt-4 mx-3">
                <li class="col-12 col-md-6 col-lg-3 p-1 d-flex">
                    <article class="law-card law-card-list__item w-100">
                        <div class="law-card-counter"></div>

                        <a href="https://tt.itmo.ru/media/storage/ЦТТ_правовои_даиджест_1_кв._2023.docx" class="law-card-link" target="_blank">Дайджест 1 квартал 2023</a>
                    </article>
                </li>
                <li class="col-12 col-md-6 col-lg-3 p-1 d-flex">
                    <article class="law-card law-card-list__item w-100">
                        <div class="law-card-counter"></div>

                        <a href="https://tt.itmo.ru/media/storage/ЦТТ_правовои_даиджест_2_кв._2023.pdf" class="law-card-link" target="_blank">Дайджест 2 квартал 2023</a>
                    </article>
                </li>
                <li class="col-12 col-md-6 col-lg-3 p-1 d-flex">
                    <article class="law-card law-card-list__item w-100">
                        <div class="law-card-counter"></div>

                        <a href="https://tt.itmo.ru/media/storage/ЦТТ_правовои_даиджест_3_кв._2023.pdf" class="law-card-link" target="_blank">Дайджест 3 квартал 2023</a>
                    </article>
                </li>
                <li class="col-12 col-md-6 col-lg-3 p-1 d-flex">
                    <article class="law-card law-card-list__item w-100">
                        <div class="law-card-counter"></div>

                        <a href="https://tt.itmo.ru/media/storage/ЦТТ_правовои_даиджест_4_кв._2023.pdf" class="law-card-link" target="_blank">Дайджест 4 квартал 2023</a>
                    </article>
                </li>
            </ul>
        </div>

        <div class="col-12 mt-5">
            <h3 class="tt-center-goal__title text-center text-lg-start mx-3">
                Правовые дайджесты ЦТТ 2022
            </h3>

            <ul class="list-unstyled law-card-list mt-4 mx-3">
                <li class="col-12 col-md-6 col-lg-3 p-1 d-flex">
                    <article class="law-card law-card-list__item w-100">
                        <div class="law-card-counter"></div>

                        <a href="https://tt.itmo.ru/media/ttc-digests/ЦТТ_правовой дайджест_1 квартал.pdf" class="law-card-link" target="_blank">Дайджест 1 квартал 2022</a>
                    </article>
                </li>
                <li class="col-12 col-md-6 col-lg-3 p-1 d-flex">
                    <article class="law-card law-card-list__item w-100">
                        <div class="law-card-counter"></div>

                        <a href="https://tt.itmo.ru/media/ttc-digests/ЦТТ_правовой дайджест_2 квартал.pdf" class="law-card-link" target="_blank">Дайджест 2 квартал 2022</a>
                    </article>
                </li>
                <li class="col-12 col-md-6 col-lg-3 p-1 d-flex">
                    <article class="law-card law-card-list__item w-100">
                        <div class="law-card-counter"></div>

                        <a href="https://tt.itmo.ru/media/storage/ЦТТ_правовои_даиджест_3_квартал.pdf" class="law-card-link" target="_blank">Дайджест 3 квартал 2022</a>
                    </article>
                </li>
                <li class="col-12 col-md-6 col-lg-3 p-1 d-flex">
                    <article class="law-card law-card-list__item w-100">
                        <div class="law-card-counter"></div>

                        <a href="https://tt.itmo.ru/media/storage/ЦТТ_правовои_даиджест_4_квартал.pdf" class="law-card-link" target="_blank">Дайджест 4 квартал 2022</a>
                    </article>
                </li>
            </ul>
        </div>

        <div class="ip-exchange__useful-things mt-5">
            <div class="technopark-numbers__title mb-4">Нормативно-правовое регулирование ЦТТ</div>

            <div class="material-card p-4 d-flex flex-column flex-xl-row w-100">
                <div class="material-card__text">Кодекс практик Центра Трансфера Технологий</div>
                <div class="mt-2 mt-xl-0 ms-xl-auto">
                    <a href="https://tt.itmo.ru/media/storage/Кодекс_практик_ЦТТ_.pdf" target="_blank"
                       class="material-card__link d-inline-block me-3">Перейти</a>
                    <img src="../../assets/views/ip-exchange/arrow-right.svg" alt="">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { onMounted } from "vue";
import routerMixin from "@/mixins/routerMixin";
import Nav from "@/components/Nav/Nav";

export default {
    name: "LawDigest",

    components: {
        Nav
    },

    setup() {
        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })
    }
}
</script>

<style>
.tt-center__main-img.gpn-center {
    background-image: url('../../assets/law-digest-bg.png')
}

.tt-center__blurred-img.gpn-center {
    background-image: unset;
}

.law-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 2rem;

    background: #304999;
    color: #fff;
    
    border-radius: 10px;
}

.law-card-list {
    display: flex;

    min-height: 320px;

    counter-reset: law-card;

    flex-flow: row wrap;

    justify-content: space-between;
}

.law-card-link,
.law-card-link:hover {
    padding-top: 0.5rem;
    color: #fff;
    text-decoration: none;
}

.law-card-link::after {
    content: url("../../assets/arrow-left.svg");
    margin-left: 0.75rem;
}

.law-card-link {
    font-size: 20px;
}

.law-card-counter::before {
    counter-increment: law-card;

    content: counter(law-card);

    width: 94px;
    height: 94px;

    display: block;

    background: #fff;
    color: #304999;

    border-radius: 100%;

    font-style: normal;
    font-weight: 700;
    font-size: 60px;

    text-align: center;
}
</style>